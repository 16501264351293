/*Navbar Styles*/
ul.nav-menu {
  position: relative;
  font-size: $font-size-base;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

ul.nav-menu li {
  position: relative;
  margin-bottom: 1px;
}

ul.nav-menu li.menu {
  & + .nav-header {
    margin-top: 40px;
  }
  & .sub-menu {
    display: none;
  }
  &.open > .sub-menu {
    display: block;
  }

  &.open {
    & .menu.active > .sub-menu {
      display: block;
    }
  }
}

ul.nav-menu li a {
  color: $sidebar-text-color;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 0;

  & .badge {
    margin-bottom: 0;
    padding: 3px 8px 2px;
  }
}

ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-color;
    padding: 4px 22px;
    font-size: 11px;
    text-transform: uppercase;

    &:first-child {
      margin-top: 30px;
    }
  }
}

ul.nav-menu > li.selected > a,
ul.nav-menu > li.open > a,
ul.nav-menu > li > a:hover,
ul.nav-menu > li > a:focus {
  color: $sidebar-hover-color;
}

ul.nav-menu li.menu > a {
  padding: 16px 20px;
}

ul.nav-menu li.menu.open > a {
  border-color: transparent;
}

ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu > a:hover:before {
  color: $sidebar-hover-color;
}

ul.nav-menu li i {
  margin-right: 12px;
  font-size: 15px;
}

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

ul.nav-menu li ul.sub-menu li > a {
  padding: 7px 20px 7px 56px;
}

ul.nav-menu li ul.sub-menu > li > ul > li > a {
  padding-left: 76px;
}

ul.nav-menu li ul.sub-menu > li > ul > li > ul > li > a {
  padding-left: 96px;
}

ul.nav-menu li ul.sub-menu > li > ul > li > ul > li > ul > li > a {
  padding-left: 116px;
}

ul.nav-menu li.menu.no-arrow > a:before {
  display: none;
}

ul.nav-menu li.menu.selected > a:before,
ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before {
  background-color: transparent;
  color: $sidebar-hover-color;
}

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu.active > a:before {
  transform: rotate(90deg);
}

ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  padding: 3px 7px;
  margin-left: -7px;
  background-color: $sidebar-hover-color;
  color: white;
  border-radius: $border-radius;
  display: inline-block;
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  color: $sidebar-hover-color;
}

.user-info {
  & ul {
    & li {
      font-size: 15px;
    }
  }
}

.drawer-container {
  position: relative;
  & .main {
    position: relative;
    padding: 10px;
    box-sizing: border-box;
  }
}
