// $np-primary: #7064b4;
// $np-primary: #7354c4;
$np-primary: #6343b1;
$np-primary-strong: #210f57;

$np-secondary: #aa4bce;
$np-secondary-darker: #8031d0;

$gradient-dark: $np-primary-strong;
$gradient-secondary: #c952e0;

$np-main-dark: #34333d;
$np-text-dark: $np-main-dark;
$np-text-medium: #605d70;
$np-text-muted: #93919c;
$np-bg-light: #f7f7fa;
$np-border-light: rgba($np-main-dark, 0.1);

$box-shadow-lg: 0 2px 24px rgba(#372b71, 0.06);
$box-shadow-alert: 0 2px 24px rgba(#372b71, 0.08);
$np-transition: all 0.25s;

$np-font-family-default: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$np-font-family-heading: 'futura-pt', sans-serif;
$np-font-family-monospace: SF Mono, Monaco, Inconsolata, Fira Mono, Droid Sans Mono,
  Source Code Pro, monospace;

$spinner-container-bg: rgba(#d5d1e6, 0.75);
$spinner-inactive: #a6a1b7;
$spinner-active: #6a5dac;
$spinner-speed: 1.2s;
$spinner-size: 42px;
$spinner-unit: calc($spinner-size / 14);
$modal-backdrop-bg: #d8d8e6;

$np-success: #39b96c;
$np-info: #34b0ed;
$np-warning: #eea600;
$np-danger: #da463c;
$np-danger-bg: #fdf7f7;
$np-danger-border: lighten($np-danger, 25%);

$np-border-rules: darken(#dbdbdc, 1%);
$spacer: 10px;

$condition-bg: #d6daff;
$action-bg: #f0e0ff;
$branch-bg: #f7f7fa;
