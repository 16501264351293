@import 'styles/global/mixins';

.p-report-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @include extra-large {
    grid-template-columns: 1fr;
  }
}

.p-report-table {
  table-layout: fixed;

  @include mobile {
    table-layout: auto;
  }

  th:first-of-type {
    width: 50px;

    @include mobile {
      width: auto;
    }
  }
}

.p-report-wrapper {
  background-color: white;
  padding: 30px;
  padding-bottom: 50px;
  height: 100%;

  @include tablet {
    padding: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.p-report-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1002 !important;
}

.p-report-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @include tablet {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.p-report-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  @include tablet {
    flex-wrap: wrap;
  }

  > * {
    flex-shrink: 0;
  }
}

.p-report-chart {
  position: relative;
  height: 450px;
}
