/*Header Styles*/

.app-logo {
  margin-right: 12px;

  .app-header & {
    margin-right: 20px;

    @media screen and (max-width: 991px) {
      margin-right: auto;
    }
  }
}

.sidebar-header {
  min-height: 70px;
  padding: 35px;
}

.sidebar-close-btn {
  background: transparent;
  color: white;
  font-size: 20px;
  padding: 0;
  border: none;
  margin-right: 15px;
}

.dropdown-menu {
  box-shadow: none;
}
