/*App Layout Styles*/

#root,
.app-main {
  display: flex;
  width: 100%;
  min-height: 100%;
}

.app-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.app-sidebar {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: $side-nav-width;
  min-width: $side-nav-width;
  max-width: $side-nav-width;
}

.app-main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: calc(100% - #{$side-nav-width});
  position: relative;

  &.app-main-container-horizontal {
    width: 100% !important;
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
  }
}

.app-header {
  min-height: $app-bar-height;
  z-index: 1001;
  position: relative;

  &.app-header-horizontal-top-below {
    min-height: $app-bar-height + 47px;

    @media screen and (max-width: 767px) {
      min-height: $app-bar-height;
    }
  }
}

.app-main-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}

.app-container {
  &.fixed-drawer {
    & .app-header .app-main-header {
      width: auto !important;
    }
  }
}
