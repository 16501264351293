.titlebar-icon + h1 {
  margin-bottom: 10px;
  font-family: 'futura-pt', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 500;
}

.titlebar-icon span {
  background: linear-gradient(
    135.21deg,
    $gradient-dark 1.66%,
    $gradient-secondary 127.92%
  );
  width: 36px;
  height: 36px;
  display: flex;
  transform: rotate(4deg);
  align-items: center;
  justify-content: center;
  margin: 0 10px 10px 0;
  border-radius: 2px;

  svg {
    fill: #fff;
    transform: rotate(-4deg);
    width: 22px;
    height: 22px;
  }
}

.services-list .name a {
  svg {
    position: relative;
    top: 3px;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  &:hover {
    text-decoration: none;

    svg {
      fill: $np-secondary;
    }
  }
}

.np-content-2col .np-content-nav-left .nav-item a.nav-link {
  svg {
    display: inline-block;
    position: relative;
    top: 4px;
    margin-right: 8px;
    opacity: 0.5;
    width: 16px;
    height: 16px;
    fill: $np-text-medium;
    transition: opacity 0.25s;
  }

  &:hover {
    color: $np-secondary-darker;

    svg {
      fill: $np-secondary-darker;
      opacity: 1;
    }
  }

  &.active {
    color: $np-secondary-darker;

    svg {
      fill: $np-secondary-darker;
      opacity: 1;
    }
  }
}

.new-portal .badge.badge-table {
  padding: 3px 8px;
  position: relative;
  top: -1px;
  margin-top: -2px;
  margin-bottom: -2px;
}

a.table-strong-link {
  svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }

  &:hover {
    text-decoration: none;

    svg {
      fill: $np-secondary-darker;
      transition: fill 0.25s;
    }
  }
}

.service-overview .panel-widget.service-info li svg {
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.service-overview .panel-widget .title-bar svg {
  margin: 0 10px 10px 0;
}

.user-2fa-empty-state svg,
.two-fa-icon {
  opacity: 0.8;
}

body.user-auth.new-portal .user-auth-wrapper {
  background: linear-gradient(160.21deg, $np-primary 0%, $gradient-dark 87.92%);
}

.new-portal .alert-info.alert-info-bg {
  background-color: rgba(saturate($np-primary, 20%), 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: none;
  margin-top: 25px;
  margin-bottom: 0;
}

.new-portal .alert {
  a {
    font-weight: 600;
    text-decoration: underline;
  }
}

.panel .title-bar .flex-group > svg {
  margin-right: 10px;
  position: relative;
  top: -5px;
}

.panel.panel-widget .panel-header .title-bar .flex-group > svg {
  top: 0px;
}

.panel .panel-header .title-bar .flex-group > svg {
  margin-right: 10px;
  position: relative;
  top: -4px;
}

.np-content-2col .np-content-nav-left .nav-item a.nav-link {
  color: $np-text-dark;
}

.np-content-2col .np-content-nav-left .nav-item-submenu ul {
  list-style: none;
  padding-left: 34px;
  padding-top: 6px;

  .nav-item a.nav-link {
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    padding: 4px 10px;
    opacity: 0.7;
    transition: opacity 0.25s;

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
      background-color: transparent;
      color: $np-text-dark;
      font-weight: 500;
    }
  }
}

.np-content-2col {
  padding-left: 230px;

  .np-content-nav-left {
    width: 200px;
  }

  @media (max-width: 768px) {
    padding-left: 0;

    .np-content-nav-left {
      width: 100%;
    }

    .nav-item-submenu {
      display: none;
    }
  }
}

.badge.badge-readonly {
  background-color: lighten($np-primary, 47%);
  color: $np-text-medium;
}

.badge.badge-small {
  font-size: 10px;
  padding: 3px 8px;
  border-radius: 10px;
}

table.services-list .service-value a {
  margin-right: 3px;

  & + .badge {
    position: relative;
    top: -1px;
  }
}

.new-portal .input-small {
  max-width: 120px;
}

.new-portal .input-medium {
  max-width: 260px;
}

.new-portal .modal .form-field + .form-field {
  margin-top: 20px;
}

@media (max-width: 1194px) {
  .new-portal .panel .settings-section .settings-info {
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) {
  .np-content-2col .np-content-nav-left .nav-item a.nav-link svg {
    display: none;
  }

  .np-content-2col .np-content-nav-left .nav {
    max-width: 540px;
  }
}

.anchor-target {
  position: relative;
  top: -80px;
}
