@import './variables';

@mixin mobile {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $md) {
    @content;
  }
}

@mixin tablet-only {
  @media (max-width: $md) and (min-width: $sm) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin laptop-only {
  @media (max-width: $lg) and (min-width: $md) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin laptop-and-desktop {
  @media (min-width: $md) {
    @content;
  }
}

@mixin extra-large {
  @media (max-width: $xxl) {
    @content;
  }
}
