.react-select {
  input, input[type=text] {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    background-color: #fff;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding: 0;
    height: auto;
  }

  input:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    background-color: #fff;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.monaco-editor textarea:focus {
  box-shadow: none !important;
}
