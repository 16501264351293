@import '../../styles/mirna/np-variables';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

.wrapper {
  background-color: #d8d8e6;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.spinner {
  display: block;
  float: left;
  width: $spinner-size;
  height: $spinner-size;
  border-radius: 50%;
  border: $spinner-unit solid $spinner-inactive;
  animation: spinner $spinner-speed linear infinite;
  position: relative;
  animation-delay: var(--spinner-delay);

  &:before {
    content: '';
    position: absolute;
    top: -$spinner-unit;
    left: -$spinner-unit;
    display: block;
    width: $spinner-size;
    height: $spinner-size;
    border-radius: 50%;
    border: $spinner-unit solid transparent;
    border-top-color: $spinner-active;
    top: ($spinner-unit * -3);
    left: ($spinner-unit * -3);
    width: ($spinner-size + $spinner-unit * 4);
    height: ($spinner-size + $spinner-unit * 4);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.local {
  position: absolute;
}

.loadingWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.loadingText {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px;
}

.loadingContent {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #f00;
}

.loadingContent:after {
  content: '';
  position: absolute;
  border: 3px solid #0f0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

.loadingContent:before {
  content: '';
  position: absolute;
  border: 3px solid #00f;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

.loadingContent {
  border: 3px solid transparent;
  border-top-color: #4d658d;
  border-bottom-color: #4d658d;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
  animation-delay: var(--spinner-delay);
}

.loadingContent:before {
  border: 3px solid transparent;
  border-top-color: #d4cc6a;
  border-bottom-color: #d4cc6a;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

.loadingContent:after {
  border: 3px solid transparent;
  border-top-color: #84417c;
  border-bottom-color: #84417c;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.icon {
  width: $spinner-size;
  height: $spinner-size;
  border: $spinner-unit solid $spinner-inactive;
  border-top-color: $spinner-active;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
