@import '../mirna/_np-variables.scss';
@import '../global/variables';
@import '../global/mixins';

.modal-backdrop.show {
  background: white;
  opacity: 0.4;
}

.modal-content {
  border-radius: 0;
  border: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.btn-transparent {
  background-color: transparent;
}

.dropdown-toggle {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

// TODO (@leonel): Build a reusable class and put some in individual modules
.collapse-submenu {
  background-color: transparent !important;
  padding-left: 30px !important;
}

.service-card {
  background-color: #00e640;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  color: #222;

  &.disabled {
    background-color: #dadfe1;
    color: #5c5c5c;
    opacity: 0.8;
  }
}

.service-card-actions {
  text-align: right;

  .btn {
    margin-right: 5px;
  }
}

.menu-left {
  margin-left: 0;
  padding-left: 0;

  li {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }
}

.service-header {
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  background-color: #81cfe0;

  ul {
    margin-left: 0;
    padding-left: 0;

    li {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
    }
  }
}

button:focus {
  outline: 0 !important;
}

.btn {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  height: 32px;
  padding: 4px 20px;
  border-radius: 3px;
  border: none;
  box-shadow: rgba(79, 67, 111, 0.14) 0px 0px 0px 1px inset,
    rgba(79, 67, 111, 0.06) 0px -1px 1px 0px inset;
  cursor: pointer;
  text-decoration: none;
  font-family: SF UI Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  color: #605d70;
  background-color: #f5f5fc;
  background-image: linear-gradient(#fcfcff 0%, #f3f2f5 100%);
  transition: all 0.25s;
}

.btn:hover {
  background-color: #ebecf0;
  background-image: linear-gradient(#f3f2fa 0%, #e5e4ed 100%);
}

.btn:active {
  background-color: #e0dfeb;
  background-image: none;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(112, 100, 180, 0.3);
}

.btn.btn-primary {
  color: #fff;
  background-color: $np-primary;
  background-image: linear-gradient($np-primary, darken($np-primary, 15%));

  &:hover {
    background-image: linear-gradient(darken($np-primary, 6%), darken($np-primary, 22%));
  }
}

.btn-primary:active {
  background-color: #392869 !important;
  background-image: none;
}

.btn-primary:focus {
  box-shadow: 0 0 0 2px rgba(112, 100, 180, 0.5);
}

.btn.btn-danger {
  color: #da463c;
  background-color: #fff8f8;
  background-image: linear-gradient(#fff8f8 0%, #fff1f0 100%);
  box-shadow: rgba(212, 70, 66, 0.14) 0px 0px 0px 1px inset,
    rgba(212, 70, 66, 0.06) 0px -1px 1px 0px inset;
}

.btn.btn-danger:hover {
  color: #da463c;
  background-image: none;
  background-color: rgba(212, 70, 66, 0.1);
  box-shadow: rgba(212, 70, 66, 0.2) 0px 0px 0px 1px inset,
    rgba(212, 70, 66, 0.1) 0px -1px 1px 0px inset;
}

.btn.btn-danger:active {
  background-color: rgba(212, 70, 66, 0.15);
}

.btn.btn-danger:focus {
  box-shadow: 0 0 0 2px rgba(212, 70, 66, 0.4);
}

.btn.btn-disabled,
.btn.btn-disabled:hover,
.btn.btn-disabled:active,
.btn.btn-disabled:focus {
  opacity: 0.5;
  cursor: default;
  background-image: none;
  background-color: #f5f5fc;
  color: #605d70;
  box-shadow: rgba(79, 67, 111, 0.14) 0px 0px 0px 1px inset,
    rgba(79, 67, 111, 0.06) 0px -1px 1px 0px inset;
}

.btn-icon {
  padding: 6px;
}

.btn-icon svg {
  opacity: 0.7;
  width: 20px;
  height: 20px;
  transition: all 0.25s;
}

.btn-icon:hover svg {
  opacity: 1;
}

.btn-icon-before {
  padding-left: 8px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.btn-icon-before svg {
  opacity: 0.7;
  width: 20px;
  height: 20px;
  transition: all 0.25s;
  margin-right: 5px;
}

.btn-icon-before:hover svg {
  opacity: 1;
}

.btn-icon-after {
  padding-left: 15px;
  padding-right: 8px;
  display: flex;
  align-items: center;
}

.btn-icon-after svg {
  order: 2;
  opacity: 0.7;
  width: 20px;
  height: 20px;
  transition: all 0.25s;
  margin-left: 5px;
}

.btn-icon-after:hover svg {
  opacity: 1;
}

.btn.btn-small {
  padding: 0 12px;
  height: 24px;
}

.btn.btn-icon.btn-small {
  padding: 2px;
}

.btn.btn-icon.btn-small svg {
  width: 20px;
  height: 20px;
}

.btn.btn-icon-before.btn-small {
  padding-left: 4px;
  padding-right: 10px;
}

.btn.btn-icon-before.btn-small svg {
  margin-right: 4px;
}

.btn.btn-icon-after.btn-small {
  padding-left: 10px;
  padding-right: 4px;
}

.btn.btn-icon-after.btn-small svg {
  margin-left: 4px;
}

.btn-toggle-group {
  white-space: nowrap;
  .btn {
    border-radius: 0;
    margin-left: -1px;

    &:focus {
      position: relative;
      z-index: 1;
    }
  }

  .btn:first-child {
    border-radius: 3px 0px 0px 3px;
  }

  .btn:last-child {
    border-radius: 0px 3px 3px 0px;
  }

  .btn.btn-max-min {
    border-radius: 3px;
  }
}

// Mirna's form fields
$primary: #7064b4;
$primary-hover: #584d96;
$primary-active: #453b7d;
$secondary: #8431d9;
$dark: #605d70;
$light: #e0dfeb;
$succes: #309e13;
$danger: #da463c;
$success-on-dark-bg: #54d138;
$danger-on-dark-bg: #fc6560;

$text: #34333d;
$text-muted: #93919c;
$text-light: #b0aeb7;

$input-bg-gradient: linear-gradient(#fafafa 0%, #f8f7fa 100%);
$input-focus-outline: #cac7e5;

$primary-gradient: linear-gradient(#8074c2 0%, #6458a8 100%);
$primary-gradient-hover: linear-gradient(#695ea9 0%, #524791 100%);
$light-gradient: linear-gradient(#fcfcff 0%, #f3f2f5 100%);
$light-gradient-hover: linear-gradient(#f3f2fa 0%, #e5e4ed 100%);

*,
:after,
:before {
  box-sizing: border-box;
}

body {
  font-family: SF UI Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  color: $text;
  font-size: 13px;
}

.form-field {
  width: 200px;
  margin-bottom: 20px;
  position: relative;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
select,
textarea {
  font-family: SF UI Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  width: 100%;
  color: $text;
  line-height: 24px;
  padding: 4px 6px;
  height: 32px;
  border: none;
  border-radius: 3px;
  font-size: 13px;
  box-shadow: rgba(67, 90, 111, 0.2) 0px 0px 0px 1px inset,
    rgba(67, 90, 111, 0.14) 0px 1px 2px inset;
  transition: all 0.25s;
  background-image: $input-bg-gradient;

  &::placeholder {
    text-transform: none;
  }
}

textarea {
  height: auto;
}

input::placeholder {
  opacity: 0.7;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: rgba(112, 100, 180, 0.7) 0px 0px 0px 1px inset,
    rgba(112, 100, 180, 0.3) 0px 1px 2px inset, 0 0 0 2px rgba(112, 100, 180, 0.3);
}

input[type='time']:focus {
  box-shadow: none;
}

label,
.label {
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin-bottom: 6px;
  cursor: pointer;
}

label.form-checkbox,
label.form-radio {
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  input + span {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    position: relative;
    border: 1px solid rgba($dark, 0.3);
    background-color: #fff;
    background-image: $input-bg-gradient;
    transition: all 0.25s;
  }

  &:hover input + span {
    border-color: rgba($primary, 0.7);
    background-color: $light;
  }

  input:active + span,
  input:focus + span {
    box-shadow: rgba(112, 100, 180, 0.7) 0px 0px 0px 1px inset,
      rgba(112, 100, 180, 0.3) 0px 1px 2px inset, 0 0 0 2px rgba(112, 100, 180, 0.3);
  }

  input:checked + span {
    background-color: $primary;
    border-color: $primary;
  }

  &:hover input:checked + span {
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
}

.form-checkbox {
  span {
    border-radius: 3px;
    position: relative;
  }

  input:checked + span {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
    background-size: 16px;
    background-position: top -1px left -1px;
  }
}

.form-radio {
  input + span {
    border-radius: 50%;
    position: relative;

    &:before {
      content: '';
      display: none;
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 4px;
      left: 4px;
    }
  }

  input:checked + span {
    background-image: none;

    &:before {
      display: block;
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 24px;

  &.error {
    border: 1px solid $danger;
    box-shadow: none;
  }
}

@font-face {
  font-family: text-security-disc;
  src: url('https://raw.githubusercontent.com/noppa/text-security/master/dist/text-security-disc.woff');
}

.input-secure {
  font-family: text-security-disc;
  -webkit-text-security: disc;
}

.select {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 12px;
    height: 32px;
    background-color: red;
    right: 2px;
    top: 0;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 4px center/8px 10px;
    pointer-events: none;
  }

  option {
    &:disabled {
      color: #93919c;
    }
  }
}

.valign-center {
  display: flex;
  align-items: center;
}

// by leonel

//.highcharts-container {
//  position: inherit !important;
//}
//.highcharts-tooltip {
//  z-index: 9998;
//}
$text: #34333d;
$text-muted: #93919c;
$text-light: #b0aeb7;

$border-light: #e7e7ea;
$border-dark: #c2c2c5;
$table-row-bg: rgba(#9d60fb, 0.05);

.dashboard-table {
  border-collapse: collapse;
  width: 100%;

  tr:hover td {
    background-color: $table-row-bg;
  }

  th {
    line-height: 1.4em;
    padding: 6px 10px;
    text-align: left;
    font-weight: 600;
    font-size: 12px;
  }

  td {
    line-height: 1.4em;
    padding: 7px 10px;
    border-top: 1px solid $border-light;

    &.text-right {
      text-align: right;
    }

    span.historic {
      display: block;
      color: $text-muted;
      margin-top: 2px;
    }
  }

  thead + tbody tr:first-child td {
    border-top-color: $border-dark;
  }

  tfoot.tfooter {
    td {
      font-weight: 600;
    }

    tr:first-child td {
      border-top-color: $border-dark;
    }

    tr:last-child td {
      border-bottom: 1px solid $border-dark;
    }
  }
}

span.error-message {
  color: $danger;
}

.new-portal {
  .container-fluid.certificates-container {
    display: flex;
    padding-right: 0;
    padding-left: 0;
    overflow: hidden;

    .np-content {
      max-width: 100%;
      width: 100%;
      padding: 30px;

      @media (max-width: 580px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .certificate-table {
      th {
        color: #605d70;
      }

      td:first-of-type {
        @media (max-width: 992px) {
          white-space: nowrap;
        }
      }
    }
  }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #39b96c;
  box-shadow: 0px 2px 24px rgba(57, 185, 108, 0.3);
  border-radius: 6px;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #da463c;
  box-shadow: 0px 2px 24px rgba(57, 185, 108, 0.3);
  border-radius: 6px;
}

.table-responsive {
  overflow: visible;

  @include mobile {
    overflow-y: auto;
  }
}
